<template>
    <footer class="footer">
        <div id="footer-bar" class="footer-bar-1">
            <router-link :to="procedureUrl"><i class="uil-list-ul"></i> <span>Procedures</span></router-link>
            <router-link :to="assemblyAreaUrl"><i class="uil-users-alt"></i> <span>Assembly Area</span></router-link>
            <router-link :to="needHelpUrl"><i class="uil-file-question-alt"></i> <span>Help</span></router-link>
        </div>
    </footer>
</template>

<script>
import {
    version
} from '@/package.json';

export default {
    data() {
        return {
            currentVersion: version,
        }
    },
    computed: {
        locationId() {
            if (this.$route.params.locationId) {
                return this.$route.params.locationId;
            }
            return "x"
        },
        designationId() {
            if (this.$route.params.designationId) {
                return this.$route.params.designationId;
            }
            return "x"
        },
        categoryId() {
            if (this.$route.params.categoryId) {
                return this.$route.params.categoryId;
            }
            return ""
        },
        procedureUrl() {
            if (this.categoryId) {
                return `/${this.locationId}/designation/${this.designationId}/selectedCategory/${this.categoryId}`;
            }
            return `/openapp/${this.locationId}/default-procedure`;
        },
        assemblyAreaUrl() {
            return `/openapp/${this.locationId}/default-assemblyarea`;
        },
        needHelpUrl() {
            return `/openapp/${this.locationId}/default-messaging`;
        }
    },
    methods: {
        logout() {
            if (confirm("are you sure ?")) {
                this.$router.push("/logout");
            }
        }
    }
}
</script>

<style>
#footer-bar {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 98;
    background-color: rgba(249, 249, 249, 0.98);
    box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.06);
    min-height: 60px;
    height: calc(62px + (constant(safe-area-inset-bottom))*1.1);
    height: calc(62px + (env(safe-area-inset-bottom))*1.1);
    display: flex;
    text-align: center;
}

#footer-bar a {
    color: #1f1f1f;
    padding-top: 12px;
    position: relative;
    flex: 1 1 auto;
}

#footer-bar a span {
    position: relative;
    z-index: 2;
    display: block;
    font-size: 10px;
    font-weight: 500;
    margin-top: -6px;
    opacity: 0.7;
    font-family: "Roboto", sans-serif !important;
}

#footer-bar a i {
    font-size: 18px;
    position: relative;
    z-index: 2;
}

#footer-bar .badge {
    font-style: normal;
    z-index: 5;
    top: 0px;
    position: absolute;
    margin-left: 3px;
    color: #FFF !important;
    width: 18px;
    text-align: center;
    line-height: 18px;
    padding: 0px;
    padding-left: 0px !important;
    border-radius: 18px;
    margin-top: 7px;
    font-size: 11px;
}
</style>
