<script>
// import { authComputed } from '@state/helpers'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    components: {
        VuePerfectScrollbar,
    },
    props: {
        isMenuOpened: {
            type: Boolean,
            default: false,
        },
        routeTitle: {
            type: String,
            default: "EvacOne"
        }
    },
    data() {
        return {
            deferredPrompt: null,
        }
    },
    computed: {
        // ...authComputed,
    },
    async created() {
    },
    methods: {

        toggleMenu() {
            this.$parent.toggleMenu()
        },
        toggleRightSidebar() {
            // this.$parent.toggleRightSidebar();
            // document.body.classList.toggle('right-bar-enabled');           
            this.$root.$emit('openRightSideBar');
        }
    },
}
</script>

<template>
    <!-- Topbar Start -->
    <div class="navbar navbar-expand flex-column flex-md-row navbar-custom bg-primary">
        <div class="container-fluid">
            <!-- LOGO -->
            <a href="/" class="navbar-brand mr-0 mr-md-2 logo" :class="{ open: !isMenuOpened }">
                <span class="logo-lg">
                    <img src="@assets/images/logo.png" alt height="36" />
                </span>
                <span class="logo-sm">
                    <img src="@assets/images/logo.png" alt height="36" />
                </span>
            </a>

            <ul class="navbar-nav bd-navbar-nav flex-row list-unstyled menu-left mb-0 mt-1">
                <li class>
                    <a href="javascript:void(0);" class="text-white h4 align-middle nav-link">{{ routeTitle }}</a>
                </li>
            </ul>
            <vs-spacer></vs-spacer>
            <template>
                <h6 class="text-right">
                    <span v-if="$store.getters.currentLocation" class="pro-user-desc text-white d-block mb-1">Location:
                        {{
                            $store.getters.currentLocation.name }}</span>
                    <span v-if="$store.getters.loggedInUser" class="text-white">Name: {{
                        $store.getters.loggedInUser.name }}</span>
                </h6>
            </template>
        </div>
    </div>
    <!-- end Topbar -->
</template>

<style lang="scss">
.noti-scroll {
    height: 220px;
}

.ps>.ps__scrollbar-y-rail {
    width: 8px !important;
    background-color: transparent !important;
}

.ps>.ps__scrollbar-y-rail>.ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y>.ps__scrollbar-y-rail>.ps__scrollbar-y,
.ps>.ps__scrollbar-y-rail:active>.ps__scrollbar-y,
.ps>.ps__scrollbar-y-rail:hover>.ps__scrollbar-y {
    width: 6px !important;
}

.button-menu-mobile {
    outline: none !important;
}
</style>
